.article {
    max-width: 100%;
}

.article img {
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 400px) {

    /* For tablets: */
    .article img {
        max-width: 200px;
        max-height: 200px;
        margin: 0;
        padding: 0;
    }
}